import styled from '@emotion/styled';

export const SideMenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 56px;

  width: 70vw;
  height: calc(100vh - 56px);
  max-height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 0 24px 24px 0;
  z-index: 9;

  transition: transform 0.2s ease-in;
  opacity: 0;
  transform: translateX(-100%);

  &.active {
    opacity: 1;
    transform: translateX(0);
  }

  @media screen and (min-width: 700px) {
    width: calc(700px - 20%);
  }

  @media (display-mode: fullscreen) {
    height: calc(100vh - 40px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    top: 0;
    padding-bottom: 40px;
  }
`;

export const SideBar = styled.div`
  padding: 30px 15px;
  height: calc(100% - 106px);
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SideBarTop = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SideBarBottom = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SideBarBack = styled.div`
  cursor: pointer;
  padding-top: 28px;
  padding-left: 20px;
  width: fit-content;

  @media (display-mode: fullscreen) {
    padding-top: env(safe-area-inset-top);
    padding-left: 15px;
  }
`;

export const SideBarLink = styled.a`
  display: block;
  cursor: pointer;
  outline: none !important;
  text-decoration: none;
  color: inherit;
`;

export const SideBarPoint = styled.li`
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  display: inline-block;
  padding: 0;
  color: #ffffff;
`;

export const SideBarPointExt = styled(SideBarPoint)`
  color: #EB1717;
`;

export const SideBarPointGray = styled(SideBarPoint)`
  font-size: 14px;
  line-height: 18px;
  padding: .25rem 0;
  color: #FFFFFFB2;
`;
